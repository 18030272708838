var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submitMethod($event)
        }
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  autofocus: "",
                  required: "",
                  label: "Line Item Name",
                  placeholder: "Add line item here..."
                },
                model: {
                  value: _vm.editedLineItem.name,
                  callback: function($$v) {
                    _vm.$set(_vm.editedLineItem, "name", $$v)
                  },
                  expression: "editedLineItem.name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-text-field", {
                attrs: {
                  required: "",
                  label: "Quantity",
                  type: "number",
                  rules: [
                    function(v) {
                      return (
                        (v && Number(v) >= 0) ||
                        "Quantity is required and must be at least 0"
                      )
                    }
                  ]
                },
                model: {
                  value: _vm.editedLineItem.quantity,
                  callback: function($$v) {
                    _vm.$set(_vm.editedLineItem, "quantity", $$v)
                  },
                  expression: "editedLineItem.quantity"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-text-field", {
                attrs: {
                  required: "",
                  label: "Price",
                  rules: [
                    function(v) {
                      return (
                        ("" !== v && !isNaN(Number(v))) || "Price is required"
                      )
                    }
                  ]
                },
                model: {
                  value: _vm.editedLineItem.override_cost,
                  callback: function($$v) {
                    _vm.$set(_vm.editedLineItem, "override_cost", $$v)
                  },
                  expression: "editedLineItem.override_cost"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c("v-checkbox", {
                attrs: { label: "Taxable" },
                model: {
                  value: _vm.editedLineItem.tax,
                  callback: function($$v) {
                    _vm.$set(_vm.editedLineItem, "tax", $$v)
                  },
                  expression: "editedLineItem.tax"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("v-textarea", {
                attrs: {
                  label: "Description",
                  placeholder: "Add description here...",
                  "auto-grow": ""
                },
                model: {
                  value: _vm.editedLineItem.note,
                  callback: function($$v) {
                    _vm.$set(_vm.editedLineItem, "note", $$v)
                  },
                  expression: "editedLineItem.note"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c("FileUploader", {
                attrs: {
                  multiple: "",
                  files: _vm.editedLineItem.photos,
                  parentId: Number(_vm.editedLineItem.id)
                },
                on: { fileChanged: _vm.imageFileChanged }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _vm.hasPhotos
              ? _c(
                  "div",
                  { staticClass: "line-items__line-item-photos" },
                  _vm._l(_vm.editedLineItem.photos, function(image, index) {
                    return _c("ImageComponent", {
                      key: "photo-" + index + "-" + image.id,
                      attrs: {
                        image: image,
                        "show-title": false,
                        size: "thumbnail"
                      },
                      on: {
                        imageDelete: function(e) {
                          return _vm.handleImageDeleteClick(e)
                        }
                      }
                    })
                  }),
                  1
                )
              : _vm._e()
          ])
        ],
        1
      ),
      _c(
        "Dialog",
        _vm._g(
          {
            attrs: { options: _vm.dialogDelete.options },
            model: {
              value: _vm.dialogDelete.show,
              callback: function($$v) {
                _vm.$set(_vm.dialogDelete, "show", $$v)
              },
              expression: "dialogDelete.show"
            }
          },
          {
            cancel: function() {
              return _vm.cancelDelete("dialogDelete")
            },
            confirm: _vm.confirmDelete
          }
        )
      ),
      _c(
        "Dialog",
        _vm._g(
          {
            attrs: { options: _vm.imageDialogDelete.options },
            model: {
              value: _vm.imageDialogDelete.show,
              callback: function($$v) {
                _vm.$set(_vm.imageDialogDelete, "show", $$v)
              },
              expression: "imageDialogDelete.show"
            }
          },
          {
            cancel: function() {
              return _vm.cancelDelete("imageDialogDelete")
            },
            confirm: _vm.confirmDeleteImage
          }
        )
      ),
      _c("hidden-submit-button")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }