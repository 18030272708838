<template>
  <v-form @submit.prevent="submitMethod">
    <v-row>
      <v-col cols="12">
        <v-text-field
          autofocus
          required
          v-model="editedLineItem.name"
          label="Line Item Name"
          placeholder="Add line item here..."
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
            required
            v-model="editedLineItem.quantity"
            label="Quantity"
            type="number"
            :rules="[v => (v && Number(v) >= 0) || 'Quantity is required and must be at least 0']"
        />
      </v-col>
      <v-col cols="8">
        <v-text-field
            required
            v-model="editedLineItem.override_cost"
            label="Price"
            :rules="[v => (''!==v && !isNaN(Number(v))) || 'Price is required']"
        />
      </v-col>
      <v-col cols="4">
        <v-checkbox
            v-model="editedLineItem.tax"
            label="Taxable"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea
            v-model="editedLineItem.note"
            label="Description"
            placeholder="Add description here..."
            auto-grow
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <FileUploader
          multiple
          :files="editedLineItem.photos"
          @fileChanged="imageFileChanged"
          :parentId="Number(editedLineItem.id)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="line-items__line-item-photos" v-if="hasPhotos">
          <ImageComponent
            v-for="(image, index) in editedLineItem.photos"
            :key="'photo-' + index + '-' + image.id"
            :image="image"
            :show-title="false"
            size="thumbnail"
            @imageDelete="e => handleImageDeleteClick(e)"
          />
        </div>
      </v-col>
    </v-row>
    <Dialog
      v-bind:options="dialogDelete.options"
      v-model="dialogDelete.show"
      v-on="{
        cancel: () => cancelDelete('dialogDelete'),
        confirm: confirmDelete
      }"
    />
    <Dialog
      v-bind:options="imageDialogDelete.options"
      v-model="imageDialogDelete.show"
      v-on="{
        cancel: () => cancelDelete('imageDialogDelete'),
        confirm: confirmDeleteImage
      }"
    />
    <hidden-submit-button />
  </v-form>
</template>

<script>
import ImageComponent from '@/domains/proposals/components/image';
import Dialog from '@/components/common/Dialog';
import HiddenSubmitButton from '@/components/common/HiddenSubmitButton.vue';
import FileUploader from '@/components/common/FileUploader.vue';
import _max from 'lodash-es/max';
import _map from 'lodash-es/map';
import { generateLineItem } from '@/domains/proposals/utils/line-items';
import { recursivelyCloneObject } from '@/utils/misc';

export default {
  name: 'LineItemEditor',

  components: {
    Dialog,
    FileUploader,
    HiddenSubmitButton,
    ImageComponent,
  },

  props: {
    lineItem: Object,
  },

  data: function () {
    const editedLineItem = null === this.lineItem ? this.getNewLineItem() : recursivelyCloneObject(this.lineItem);

    return {
      showDeleteBtn: null !== this.lineItem,
      editedLineItem,
      dialogDelete: {
        show: false,
        deleteCallback: null,
        options: {
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this line item?',
          buttons: {
            cancel: {
              label: 'Cancel',
              event: 'cancel',
              attr: {
                color: 'default',
                text: true,
              },
            },
            confirm: {
              label: 'OK',
              event: 'confirm',
              attr: {
                color: 'primary',
              },
            },
          },
        },
      },
      imageDialogDelete: {
        show: false,
        deleteCallback: null,
        options: {
          title: 'Are you sure?',
          text: 'Are you sure you want to delete this photo?',
          buttons: {
            cancel: {
              label: 'Cancel',
              event: 'cancel',
              attr: {
                color: 'default',
                text: true,
              },
            },
            confirm: {
              label: 'OK',
              event: 'confirm',
              attr: {
                color: 'primary',
              },
            },
          },
        },
      },
    };
  },

  computed: {
    hasPhotos() {
      return (
          null === this.editedLineItem.header_type &&
          /* @TODO implement permission checking maybe? */
          // curate.AC.has("proposal.item.photos") &&
          this.editedLineItem.photos?.length > 0
      );
    },

    photosChanged() {
      return this.lineItem?.photos.length !== this.editedLineItem.photos.length
        // When lengths are equal but not 0 check the values for a difference
        || (this.lineItem.photos.length > 0 && this.checkPhotosForDifferentUrls());
    },

    saveBtnEnabled() {
      const lineItem = null === this.lineItem ? this.getNewLineItem() : this.lineItem;

      const nameChanged = lineItem.name !== this.editedLineItem.name;
      const qtyChanged = parseFloat(lineItem.quantity) !== parseFloat(this.editedLineItem.quantity);
      const priceChanged = parseFloat(lineItem.override_cost) !== parseFloat(this.editedLineItem.override_cost);
      const taxChanged = lineItem.tax !== this.editedLineItem.tax;
      const noteChanged = lineItem.note !== this.editedLineItem.note;
  
      return nameChanged || qtyChanged || priceChanged || taxChanged || noteChanged || this.photosChanged;
    },
  },

  methods: {
    save(close){
      if (null === this.editedLineItem.id) {
        this.$store.commit('addLineItem', this.editedLineItem);
      } else {
        this.$store.commit('updateLineItem', this.editedLineItem);
      }
      this.$store.commit('recalculatePricing');
      this.$store.commit('setCurrentChanged', true);

      close();
    },

    onDelete(close) {
      this.dialogDelete.deleteCallback = close;
      this.dialogDelete.show = true;
    },

    cancelDelete(dialog) {
      this[dialog].deleteCallback = null;
      this[dialog].show = false;
    },

    confirmDelete() {
      this.$store.commit('deleteLineItem', this.lineItem.id);
      this.$store.commit('recalculatePricing');
      this.$store.commit('setCurrentChanged', true);
      if (null !== this.dialogDelete.deleteCallback) {
        this.dialogDelete.deleteCallback();
      }
    },

    checkPhotosForDifferentUrls() {
      const originalUrls = this.lineItem.photos.map(({ url }) => url);
      const editedUrls = this.editedLineItem.photos.map(({ url }) => url);
      
      return !originalUrls.every(originalUrl => editedUrls.includes(originalUrl));
    },

    getNewLineItem() {
      let order = _max(_map(this.$store.state.proposals.current.arrangements, 'order'));
      order = order ? order + 1 : 0;

      return generateLineItem({ order });
    },

    handleImageDeleteClick(imageUrl) {
      this.imageDialogDelete.deleteCallback = () => this.deleteImage(imageUrl);
      this.imageDialogDelete.show = true;
    },

    confirmDeleteImage() {
      this.imageDialogDelete.deleteCallback();
      this.imageDialogDelete.show = false;
    },

    deleteImage(imageUrl) {
      const targetPhoto = this.editedLineItem.photos.find(photo => photo.url === imageUrl);

      // If a photo has an ID it's been saved previously and we track it to send to the backend for deletion
      if (targetPhoto.id) {
        this.editedLineItem.deleted_photos = Array.isArray(this.editedLineItem.deleted_photos) 
          ? [ ...this.editedLineItem.deleted_photos, targetPhoto ]
          : [ targetPhoto ];
      }
      
      this.editedLineItem.photos = this.editedLineItem.photos.filter(photo => photo.url !== targetPhoto.url);
    },

    imageFileChanged(imageObject) {
      if (!imageObject) {
        return;
      }

      this.editedLineItem.photos.push(imageObject);
    },

    submitMethod() {
      if (this.saveBtnEnabled) {
        this.$emit('enterPress');
      }
    },
  },
};
</script>

<style scoped>

</style>
