import { render, staticRenderFns } from "./line-item-editor.vue?vue&type=template&id=1b5f8ff9&scoped=true&"
import script from "./line-item-editor.vue?vue&type=script&lang=js&"
export * from "./line-item-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b5f8ff9",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCheckbox,VCol,VForm,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/Users/nickray/Projects/curate-web/web-client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b5f8ff9')) {
      api.createRecord('1b5f8ff9', component.options)
    } else {
      api.reload('1b5f8ff9', component.options)
    }
    module.hot.accept("./line-item-editor.vue?vue&type=template&id=1b5f8ff9&scoped=true&", function () {
      api.rerender('1b5f8ff9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/domains/proposals/components/editors/line-item-editor.vue"
export default component.exports